import React from 'react'
import './Accessibility.scss'
import Grid from '@material-ui/core/Grid';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS} from "@contentful/rich-text-types"

export default function Accessibity({data}) {
    const header = data.header
    const accessibilityContent = data.accessibilityContent
    const options = {
        renderNode: {
          [BLOCKS.HEADING_5]:(node,children)=> <h4 className='para-title'>{children}</h4>
        },
      }
  
    return (
        <>
                <Grid container xl={12} lg={12} md={12} sm={12} xs={12} className="accessibility-notice-cls">
                    <Grid item xl={1} lg={1} className="hide">&nbsp;</Grid>
                    <Grid item xl={7} lg={8} md={12} sm={12} xs={12} className="border">
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} locId="header">
                            <h1 className='header-title'>{header}</h1>
                        </Grid>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} locId="accessibilityContent">
                            <p className='paragraph'>{documentToReactComponents(JSON.parse(accessibilityContent?.raw),options)}</p>
                        </Grid>
                    </Grid>
                    <Grid item xl={4} lg={3} className="hide">&nbsp;</Grid>
                </Grid>
        </>
    )
}